import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CenteredText from "layouts/centered-text"
import ClientLogos from "components/client-logos"

const OurClients = () => {
  const { wpPage } = useStaticQuery(graphql`
    query OurClients {
      wpPage(databaseId: { eq: 3843 }) {
        about {
          ourClients {
            heading
            content
          }
        }
      }
    }
  `)

  const { heading, content } = wpPage.about.ourClients

  return (
    <div className="my-20 md:my-36 lg:my-56">
      <CenteredText heading={heading} content={content} />
      <ClientLogos />
    </div>
  )
}

export default OurClients
