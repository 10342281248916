import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Overline from "components/overline"

const Hero = () => {
  const { wpPage } = useStaticQuery(graphql`
    query AboutHero {
      wpPage(databaseId: { eq: 3843 }) {
        about {
          aboutHero {
            heading
            overline
          }
        }
      }
    }
  `)
  const { overline, heading } = wpPage.about.aboutHero

  return (
    <section className="c-hero o-container">
      <div className="max-w-2xl mx-auto text-center">
        <Overline>{overline}</Overline>
        <div className="font-serif text-72px">{heading}</div>
      </div>
    </section>
  )
}

export default Hero
