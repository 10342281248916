import React, { PureComponent } from "react"
import "intersection-observer"
import scrollama from "scrollama"
import Link from "components/transition-link"
import { renderImage } from "utils/imageRender"
import getRelativeUrl from "utils/getRelativeUrl"
import FadeIn from "transitions/fade-in"

export default class WhatWeDo extends PureComponent {
  constructor(props) {
    super(props)
    this.scroller = scrollama()

    this.setupScroller = this.setupScroller.bind(this)
    this.setStep = this.setStep.bind(this)
    this.resizeScroller = this.resizeScroller.bind(this)

    this.acc = []
    this.props.data.sections.forEach(value => {
      this.acc[value.menuTitle] = React.createRef()
    })

    this.state = {
      currentStep: 0,
      slideCount: 0,
      animate: false,
    }
  }

  componentDidMount() {
    this.setupScroller()
    this.setState({ slideCount: this.props.data.sections.length })
    window.addEventListener("resize", this.resizeScroller)
  }

  componentWillUnmount() {
    this.scroller.destroy()
    window.removeEventListener("resize", this.resizeScroller)
  }

  resizeScroller() {
    return this.scroller.resize()
  }
  setStep(step) {
    this.setState(() => ({
      currentStep: step,
      animate: step === this.state.slideCount /* If step is last slide */,
    }))
  }

  setupScroller() {
    this.scroller
      .setup({
        step: ".js-step",
        offset: 0.5 /* Sticky header height */,
        debug: false,
      })
      .onStepEnter(response => {
        this.setStep(response.index)
      })
  }

  render() {
    const { heading, introduction, link, sections } = this.props.data
    const { currentStep, animate } = this.state

    return (
      <div className={`c-scrollama ${animate ? "animate" : ""}`}>
        <div className="c-scrollama__image-container">
          <div className="relative w-full md:h-full">
            {sections.map((section, index) => (
              <div
                className={`c-scrollama__image ${
                  index === 0 || index < currentStep ? "visible" : ""
                }`}
                key={`image-${section.menuTitle}`}
              >
                {renderImage(section.image)}
              </div>
            ))}
          </div>
        </div>

        <div className="lg:w-1/2 c-scrollama__text-container">
          <section className="top-0 mb-10 text-center pointer-events-none c-scrollama__intro md:sticky md:pb-16 px-7 md:px-0 sm:mb-18 md:mb-0 js-step md:text-left">
            <div className="bg-white pointer-events-auto md:pt-20 lg:pt-30">
              <FadeIn delay="0">
                <h2 className="mb-8 font-serif text-38px">{heading}</h2>
              </FadeIn>
              <FadeIn delay="100">
                <div
                  className="mb-6 max-w-md"
                  dangerouslySetInnerHTML={{ __html: introduction }}
                ></div>
              </FadeIn>
              <FadeIn delay="150">
                {link && (
                  <Link
                    className="tg-underline text-midnight"
                    to={getRelativeUrl(link.url)}
                  >
                    {link.title}
                  </Link>
                )}
              </FadeIn>
            </div>
          </section>

          {/* Hidden at mobile */}
          <div className="z-0">
            <div>
              {sections.map((section, index) => (
                <section
                  className="c-scrollama__step js-step"
                  id={section.menuTitle}
                  key={`text-${section.menuTitle}`}
                >
                  <div ref={this.acc[section.menuTitle]} className="">
                    <h3 className="mb-5 font-serif text-2xl md:mb-8 md:text-30px">
                      {section.sectionTitle}
                    </h3>
                  </div>
                  <div
                    className="text-base c-rich-text has-asterisk-bullets"
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></div>
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
