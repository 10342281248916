import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderImage } from "utils/imageRender"
import CenteredText from "layouts/centered-text"
import FadeIn from "transitions/fade-in"
import Gallery from "components/gallery"

const WhoWeAre = () => {
  const { wpPage } = useStaticQuery(graphql`
    query WhoWeAre {
      wpPage(databaseId: { eq: 3843 }) {
        about {
          whoWeAre {
            heading
            content
            link {
              title
              url
            }
            gallery {
              image {
                ...FluidHalfWidthImage
              }
            }
            textItems {
              heading
              description
            }
          }
        }
      }
    }
  `)

  const { heading, link, content, gallery, textItems } = wpPage.about.whoWeAre

  return (
    <>
      <div className="md:hidden">
        <div>{renderImage(gallery[2].image)}</div>
      </div>
      <div className="my-16 md:my-40 lg:mb-56 who-we-are">
        <CenteredText heading={heading} content={content} link={link} />

        <div className="container">
          <Gallery gallery={gallery} name="who-we-are" />
        </div>
        {/* @todo attach this to CMS */}
        <div className="container">
          <div className="mt-6 md:mt-10 grid grid-cols-2 gap-6 gap-y-10 sm:grid-cols-3 text-center">
            {textItems.map(({ heading, description }, i) => (
              <FadeIn key={`textItem-${i}`} delay={(i / 5) * 1000 + 400}>
                <h3 className="font-serif text-40px">{heading}</h3>
                <p>{description}</p>
              </FadeIn>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default WhoWeAre
