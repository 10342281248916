import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderImage } from "utils/imageRender"
import FadeIn from "transitions/fade-in"
import handleExternalUrls from "utils/handleExternalUrls"

const AwardsAndRecognition = () => {
  const { wpPage } = useStaticQuery(graphql`
    query Awards {
      wpPage(databaseId: { eq: 3843 }) {
        about {
          awards {
            heading
            content
            image {
              ...FluidHalfWidthImage
            }
          }
        }
      }
    }
  `)
  const { heading, content, image } = wpPage.about.awards

  useEffect(() => {
    handleExternalUrls()
  }, [wpPage]) // Only re-run if wpPage changes

  return (
    <section
      id="awards"
      className="my-12 text-center c-text-and-image sm:text-left sm:my-36 lg:mb-52"
    >
      <div className="o-container">
        <div className={`grid md:grid-cols-2 items-center gap-12 md:gap-30`}>
          <div className="-mx-5 overflow-hidden md:order-1 ratio-3:2 md:ratio-1:1 md:mx-0 lg:-ml-18">
            <div>{renderImage(image)}</div>
          </div>

          <div className="flex flex-col justify-center">
            <div className="lg:pl-7 lg:mr-7">
              <FadeIn delay="0">
                <h2 className="mb-8 font-serif text-38px">{heading}</h2>
              </FadeIn>
              <FadeIn delay="100">
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AwardsAndRecognition
