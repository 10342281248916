import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FadeIn from "transitions/fade-in"
import Gallery from "components/gallery"

const OurCompany = () => {
  const { wpPage } = useStaticQuery(graphql`
    query OurCompany {
      wpPage(databaseId: { eq: 3843 }) {
        about {
          ourCompany {
            heading
            content
            gallery {
              image {
                ...FluidHalfWidthImage
              }
            }
          }
        }
      }
    }
  `)
  const { heading, content, gallery } = wpPage.about.ourCompany

  return (
    <div className="container">
      <div className="flex flex-wrap items-center -ml-12">
        <div className="w-full pl-12 md:w-7/12">
          <Gallery gallery={gallery} name="our-company" />
        </div>

        <div className="w-full pl-12 md:w-5/12">
          <div className="mx-auto my-16 text-center md:max-w-sm md:my-0 md:text-left">
            <FadeIn delay="0">
              <h2 className="mb-8 font-serif text-38px">{heading}</h2>
            </FadeIn>
            <FadeIn delay="100">
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </FadeIn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurCompany
