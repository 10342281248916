import React from "react"
import { graphql } from "gatsby"
import OurCompany from "components/about/our-company"
import SEO from "components/seo"
import Transition from "components/transition"
import Hero from "components/about/hero"
import WhoWeAre from "components/about/who-we-are"
import OurClients from "components/about/our-clients"
import AwardsAndRecognition from "components/about/awards-and-recognition"
import ExploreOurWork from "components/about/explore-our-work"
import WhatWeDo from "components/about/what-we-do"

const About = React.memo(({ data: { wpPage } }) => {
  if (!wpPage) return null
  const { seo, about } = wpPage
  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--about",
        }}
      />
      <Hero />
      <OurCompany />
      <WhoWeAre />
      <WhatWeDo data={about.whatWeDo} />
      <OurClients />
      <AwardsAndRecognition />
      <ExploreOurWork className="mt-20" data={about.exploreOurWork} />
    </Transition>
  )
})

export const query = graphql`
  {
    wpPage(databaseId: { eq: 3843 }) {
      ...SEO
      about {
        whatWeDo {
          heading
          introduction
          link {
            title
            url
          }
          sections: services {
            menuTitle
            sectionTitle
            content
            image {
              ...FluidHalfWidthImage
            }
          }
        }
        exploreOurWork {
          heading
          content
          caseStudies {
            ...CaseStudyCard
          }
        }
      }
    }
  }
`

export default About
